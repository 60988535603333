'use client';

import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useCallback } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useUpdateContentItemNameAndIconMutation } from '#/packages/content-item/queries/useContentItemMutation';
import { ContentItem } from '#/packages/content-item/types/ContentItem';
import { isFolderContentItem } from '#/packages/content-item/utils/type';
import { Button, Modal, TextInput, firstLetterUp } from '#/packages/ui';
import { useReportsMenuContext } from '../../../src/components/ReportMenu/ReportMenuProvider';

export const ContentItemMoveModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { actionAndContentItem } = useReportsMenuContext();
  const { id, name } = actionAndContentItem ?? {};

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: { id, name }
  });

  const updateName = useUpdateContentItemNameAndIconMutation(id);
  const contentType = isFolderContentItem(actionAndContentItem as ContentItem)
    ? 'folder'
    : 'report';

  const formHandler = useCallback((form: FieldValues) => {
    return Promise.all([updateName.mutateAsync({ name: form.name })]).then(() => {
      onClose();
      toast.success(`${firstLetterUp(contentType)} renamed`, { icon: false });
    });
  }, []);

  const title = `Rename ${contentType}`;

  if (!id) {
    return null;
  }

  return (
    <Modal title={title} show={true} onClose={onClose}>
      Move
    </Modal>
  );
};
