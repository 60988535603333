import { format, fromUnixTime } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ReactNode, useEffect } from 'react';
import userflow from 'userflow.js';
import { useUserQuery } from '#/src/queries/useUserQuery';

const USERFLOW_TOKEN = process.env.NEXT_PUBLIC_USERFLOW_TOKEN;

export const UserflowProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { data: userInfo } = useUserQuery();

  useEffect(() => {
    if (USERFLOW_TOKEN && userInfo?.user.id) {
      userflow.init(USERFLOW_TOKEN);

      try {
        const createdAtISO = fromUnixTime(userInfo.user.createdAt);

        const signed_up_at = format(
          utcToZonedTime(createdAtISO, 'UTC'),
          "yyyy-MM-dd'T'HH:mm:ss'Z'"
        );

        userflow.identify(userInfo.user.id, {
          name: [userInfo.user.firstName, userInfo.user.lastName].join(' '),
          email: userInfo.user.email,
          signed_up_at
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [userInfo]);

  return children;
};
