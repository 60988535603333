import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  ItemMutateContext,
  onItemOptimisticMutate,
  onItemOptimisticMutateError,
  onItemOptimisticMutateSuccess,
  setItem
} from '#/packages/content-item/queries/optimistic-utils';
import type { ContentItem } from '#/packages/content-item/types/ContentItem';
import httpClient from '#/src/api/backendHttpClient';
import { useSessionWorkspaceId } from '#/src/context/session/hooks/useWorkspace';
import { AdBreakdownSpecs } from '#/src/types/AdBreakdownModel';

export function useUpdateContentItemNameMutation(id: string | undefined) {
  const queryClient = useQueryClient();
  const workspaceId = useSessionWorkspaceId();

  return useMutation<ContentItem, AxiosError, { name: string }, ItemMutateContext | null>({
    mutationFn: (data) =>
      id == null
        ? Promise.reject('No report uuid')
        : httpClient.post(`/content-item/${id}/name`, data),
    onMutate: (data) => onItemOptimisticMutate(id, data, { workspaceId, queryClient }),
    onError: (_err, _newData, context) =>
      onItemOptimisticMutateError(context, { workspaceId, queryClient }),
    onSuccess: (contentItem) =>
      onItemOptimisticMutateSuccess(id, contentItem, { workspaceId, queryClient })
  });
}

export function useUpdateContentItemNameAndIconMutation(id: string | undefined) {
  const queryClient = useQueryClient();
  const workspaceId = useSessionWorkspaceId();

  return useMutation<ContentItem, AxiosError, { name: string }, ItemMutateContext | null>({
    mutationFn: (data) =>
      id == null
        ? Promise.reject('No report uuid')
        : httpClient.post(`/content-item/${id}/name_icon`, data),
    onMutate: (data) => onItemOptimisticMutate(id, data, { workspaceId, queryClient }),
    onError: (_err, _newData, context) =>
      onItemOptimisticMutateError(context, { workspaceId, queryClient }),
    onSuccess: (contentItem) =>
      onItemOptimisticMutateSuccess(id, contentItem, { workspaceId, queryClient })
  });
}

export function useUpdateContentItemDescriptionMutation(id: string | undefined) {
  const queryClient = useQueryClient();
  const workspaceId = useSessionWorkspaceId();

  return useMutation<ContentItem, AxiosError, string, ItemMutateContext | null>({
    mutationFn: (description) =>
      id == null
        ? Promise.reject('No report uuid')
        : httpClient.post<ContentItem>(`/content-item/${id}/description`, { description }),
    onMutate: (description) =>
      onItemOptimisticMutate(id, { description }, { workspaceId, queryClient }),
    onError: (_err, _newData, context) =>
      onItemOptimisticMutateError(context, { workspaceId, queryClient }),
    onSuccess: (contentItem) =>
      onItemOptimisticMutateSuccess(id, contentItem, { workspaceId, queryClient })
  });
}

export function useUpdateContentItemChildren() {
  const queryClient = useQueryClient();
  const workspaceId = useSessionWorkspaceId();

  return useMutation<
    ContentItem,
    Error,
    {
      contentItemId: string | null;
      children: string[];
      specs: any;
    },
    ItemMutateContext | null
  >({
    mutationFn: async ({ contentItemId, children, specs }) => {
      if (!contentItemId) {
        return Promise.reject('No content item uuid');
      }
      if (specs) {
        await httpClient.post(`/content-item/${contentItemId}/specs`, { specs });
      }

      return httpClient.post(`/content-item/${contentItemId}/children`, { children });
    },
    onMutate: ({ contentItemId, children, specs }) => {
      return onItemOptimisticMutate(
        contentItemId,
        { children, specs },
        { queryClient, workspaceId }
      );
    },
    onError: (_err, _newData, context) => {
      return onItemOptimisticMutateError(context, { workspaceId, queryClient });
    },
    onSuccess: (contentItem) => {
      return onItemOptimisticMutateSuccess(contentItem.id, contentItem, {
        workspaceId,
        queryClient
      });
    }
  });
}

export const getUpdateContentItemSpecsMutationQueryKey = (id?: ContentItem['id']) => [
  'content-item-specs',
  id
];

export function useUpdateContentItemSpecsMutation(id: string | undefined) {
  const queryClient = useQueryClient();
  const workspaceId = useSessionWorkspaceId();

  return useMutation<ContentItem, AxiosError, Pick<AdBreakdownSpecs, 'rows'>>({
    mutationKey: getUpdateContentItemSpecsMutationQueryKey(id),
    mutationFn: (specs) =>
      id == null
        ? Promise.reject('No report uuid')
        : httpClient.post(`/content-item/${id}/specs`, { specs }),
    onSuccess: (contentItem) => setItem(id || null, contentItem, { workspaceId, queryClient })
  });
}
