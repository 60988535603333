'use client';

import React, { ReactNode, createContext, useContext, useState } from 'react';
import { ContentItemDeleteModal } from '#/packages/content-item/components/ContentItemDeleteModal';
import { ContentItemMoveModal } from '#/packages/content-item/components/ContentItemMoveModal';
import { ContentItemUpdateModal } from '#/packages/content-item/components/ContentItemUpdateModal';

type ActionAndContentItemType = {
  name: string;
  id: string;
  isFolder: boolean;
  action: 'rename' | 'delete' | 'duplicate' | 'move';
};

type ReportsMenuContextType = {
  actionAndContentItem: ActionAndContentItemType | undefined;
  setActionAndContentItem: React.Dispatch<
    React.SetStateAction<ActionAndContentItemType | undefined>
  >;
};

const ReportsMenuContext = createContext<ReportsMenuContextType | undefined>(undefined);

export const ReportMenuProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [actionAndContentItem, setActionAndContentItem] = useState<ActionAndContentItemType>();

  const onClose = () => {
    setActionAndContentItem(undefined);
  };

  return (
    <ReportsMenuContext.Provider
      value={{
        actionAndContentItem,
        setActionAndContentItem
      }}
    >
      {actionAndContentItem?.action === 'rename' && <ContentItemUpdateModal onClose={onClose} />}
      {actionAndContentItem?.action === 'delete' && <ContentItemDeleteModal onClose={onClose} />}
      {actionAndContentItem?.action === 'move' && <ContentItemMoveModal onClose={onClose} />}

      {children}
    </ReportsMenuContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useReportsMenuContext = (): ReportsMenuContextType => {
  const context = useContext(ReportsMenuContext);

  if (!context) {
    throw new Error('useReportsContext must be used within a ReportMenuProvider');
  }

  return context;
};
