'use client';

import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useCallback } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useUpdateContentItemNameAndIconMutation } from '#/packages/content-item/queries/useContentItemMutation';
import { ContentItem } from '#/packages/content-item/types/ContentItem';
import { isFolderContentItem } from '#/packages/content-item/utils/type';
import { Button, Modal, TextInput, firstLetterUp } from '#/packages/ui';
import { useReportsMenuContext } from '../../../src/components/ReportMenu/ReportMenuProvider';

export const ContentItemUpdateModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { actionAndContentItem } = useReportsMenuContext();
  const { id, name } = actionAndContentItem ?? {};

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: { id, name }
  });

  const updateName = useUpdateContentItemNameAndIconMutation(id);
  const contentType = isFolderContentItem(actionAndContentItem as ContentItem)
    ? 'folder'
    : 'report';

  const formHandler = useCallback((form: FieldValues) => {
    return Promise.all([updateName.mutateAsync({ name: form.name })]).then(() => {
      onClose();
      toast.success(`${firstLetterUp(contentType)} renamed`, { icon: false });
    });
  }, []);

  const title = `Rename ${contentType}`;

  if (!id) {
    return null;
  }

  return (
    <Modal title={title} show={true} onClose={onClose}>
      <form onSubmit={handleSubmit(formHandler)}>
        <div className='sm:flex sm:items-start w-full'>
          <div className='w-full'>
            <label htmlFor='name' className='block text-sm font-medium leading-6 text-gray-900'>
              New name:
            </label>
            <div className='relative mt-2 rounded-md'>
              <TextInput
                type='text'
                id='name'
                invalid={Boolean(errors.name)}
                placeholder='E.g. Coca Cola'
                aria-invalid='true'
                aria-describedby='email-error'
                {...register('name', { required: true })}
              />
              {errors.name && (
                <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                  <ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
                </div>
              )}
            </div>
            {errors.name && (
              <p className='mt-2 text-sm text-red-600' id='email-error'>
                Please type in a name
              </p>
            )}
          </div>
        </div>
        <div className='mt-5 sm:mt-6 sm:flex sm:flex-row-reverse'>
          <Button size={'md'} color='secondary' type='submit' isLoading={isSubmitting}>
            {isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
