'use client';

import { datadogRum } from '@datadog/browser-rum';
import { AnalyticsBrowser, EventProperties } from '@segment/analytics-next';
import { FetchUserAttributesOutput, fetchUserAttributes } from 'aws-amplify/auth';
import cookie from 'js-cookie';
import { usePathname, useSearchParams } from 'next/navigation';
import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import { useSessionWorkspaceId } from '#/src/context/session/hooks/useWorkspace';
import { AnalyticsClient, AnalyticsProviderProps, AuthEvent, AuthProvider } from './types';

const NO_KEY = 'NO_KEY';

export const AnalyticsContext = createContext<AnalyticsClient | undefined>(undefined);

export const AnalyticsProvider = ({ children, writeKey }: AnalyticsProviderProps) => {
  const workspaceId = useSessionWorkspaceId();

  const client = useMemo<AnalyticsBrowser>(() => {
    return AnalyticsBrowser.load(
      { writeKey: writeKey || NO_KEY },
      { disable: writeKey === NO_KEY }
    );
  }, [writeKey]);

  const track = useCallback(
    (event: string, properties: EventProperties) => {
      return client.track(event, { ...(properties || {}), $groupId: workspaceId });
    },
    [client, workspaceId]
  );

  const analytics = useMemo<AnalyticsClient>(() => {
    return {
      ...client,

      track,

      trackExperiment: (experimentName: string, enabled: boolean) =>
        track('experiment.play', { experimentName, enabled }),

      trackAuth: (event: AuthEvent, params: { provider: AuthProvider }) =>
        track(event, {
          fbp: cookie.get('_fbp'),
          fbc: cookie.get('_fbc'),
          ...params
        }),

      identifyUser: async (userAttributes: FetchUserAttributesOutput) => {
        if (!userAttributes.sub) {
          return;
        }

        await client.identify(userAttributes.sub, {
          lastName: userAttributes.family_name,
          firstName: userAttributes.given_name,
          email: userAttributes.email
        });

        datadogRum.setUser({
          id: userAttributes.email,
          name: `${userAttributes.given_name} ${userAttributes.family_name}`,
          email: userAttributes.email
        });
      }
    } as AnalyticsClient;
  }, [track, client]);

  useEffect(() => {
    (async function () {
      try {
        const userAttributes = await fetchUserAttributes();

        await analytics.identifyUser(userAttributes);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e?.message === 'Auth UserPool not configured.' && writeKey === NO_KEY) {
          return;
        } else {
          console.error(e);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnalyticsContext.Provider value={analytics}>
      <AnalyticsPageListener analytics={analytics} workspaceId={workspaceId} />
      {children}
    </AnalyticsContext.Provider>
  );
};

function AnalyticsPageListener(props: { analytics: AnalyticsClient; workspaceId: string | null }) {
  const analytics = props.analytics;
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    analytics?.page('Superads app', 'Page', {
      $groupId: props.workspaceId
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics, pathname, searchParams]);

  return null;
}
